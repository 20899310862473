






import { DeviceRelation } from '@/models/data/models';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { dataRouteName } from '../app';

@Component({})
export default class DeviceDetailDevice extends Vue {
  /**
   * ID of device
   */
  @Prop({ required: true }) id!: string;

  /**
   * Find the device relation by device UUID and replace route by device relation detail
   */
  async mounted(): Promise<void> {
    try {
      const application =
        this.$store.getters['global/application']?.id ?? 'unknown';
      const deviceRelation = await this.$apiv2.find<DeviceRelation>(
        DeviceRelation,
        {
          application,
          device: this.id,
        },
      );
      await this.$router.replace({
        name: dataRouteName('device-relation-detail'),
        params: {
          id: deviceRelation.id,
        },
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
      this.$router.replace({
        name: dataRouteName('device-relation-root'),
      });
    }
  }
}
